import React, { useCallback, useState } from 'react'
import { Button, Checkbox } from 'components/ui'
import { HiPlusSm, HiMinusSm } from 'react-icons/hi'
//import { CgCodeSlash, CgCode, CgCopy } from 'react-icons/cg'
import { Link } from 'react-router-dom'


// const demos = [
//   {
//     mdName: 'Simple',
//     title: 'Simple',
//   }
// ]




const ChecklistTable = () => {
  const [expand, setExpand] = useState(false)

  const onExpand = useCallback(() => {
      setExpand(!expand)
  }, [expand])
  return (
   <>
    <div className="lg:flex items-center justify-between mb-4">
                        <Checkbox>Images Optimized</Checkbox> 
                        <Button 
                        onClick={() => onExpand()} 
                        variant="plain" 
                        shape="circle" 
                        size="xs" 
                        icon={expand ? <HiMinusSm />: <HiPlusSm />} 
                        />
                    
                    </div>
                    <div className={expand ? 'block' : 'hidden'}>
                    <ul>
                            <li>Compress all your images using either web processors such as <Link to="https://web.archive.org/web/20190624185304/http://optimizilla.com/" >Optimizilla.com</Link> and <Link to="https://web.archive.org/web/20190624185304/https://compressor.io/compress" >Compressor.io</Link>, or desktop apps such as <Link  rel="noopener" to="https://web.archive.org/web/20190624185304/http://nikkhokkho.sourceforge.net/static.php?page=FileOptimizer">FileOptimizer</Link>
                                and <Link to="https://web.archive.org/web/20190624185304/https://imageoptim.com/mac">ImageOptim</Link>.
                            </li>
                            <li>Generate the exact image size for each element instead of resizing it with CSS/HTML as
                                this can be a heavy process for the browser to perform.
                            </li>
                            <li>Utilize <Link to="https://web.archive.org/web/20190624185304/https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Images/Implementing_image_sprites_in_CSS">Image
                                Sprites</Link> to save on HTTP requests and bandwidth.
                            </li>
                        </ul>
                    </div>
   </>
  )
}

export default ChecklistTable