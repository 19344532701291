import { Card } from "components/ui"

const Dashboard = () => {
  return (
    <>
    
    <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6  py-4 sm:py-6 md:px-8">
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
    <Card>
    <h5>1250 Checklist</h5>

    </Card>
    <Card>15 Groups</Card>
            </div>
            {/* END #1 */}
    </div>{/* END .page-container */}


    </>
  )
}



export default Dashboard