import { Card, Dialog, Button, Tooltip, Avatar } from 'components/ui'
import React, { useState } from 'react'

import {HiOutlineClipboardCheck, HiOutlinePencilAlt, HiTrash, HiDuplicate} from 'react-icons/hi'
import { Link } from 'react-router-dom';

import gaurav from '../../assets/images/avatars/gaurav.jpg';
import vsr from '../../assets/images/avatars/vsr.jpg';
import devendra from '../../assets/images/avatars/devendra-singh-nathawat.jpg';
import anand from '../../assets/images/avatars/anand-saini.jpg';
import sandeep from '../../assets/images/avatars/sandeep.jpg';
import vijendraverma from '../../assets/images/avatars/vv.jpg';
import NewForm from './NewForm';

const ChecklistGroup = ({cardBorder}) => {

    const [dialogIsOpen, setIsOpen] = useState(false)

	const openDialog = () => {
		setIsOpen(true)
	}

	const onDialogClose = e => {
		console.log('onDialogClose', e)
		setIsOpen(false)
	}

	 

const checklists = [  
    {  
      'id': 1,   
      'projectname': 'PSD to HTML and WordPress',  
      'clientname': 'John Anderson',  
      'duration': '45 minutes',  
      'mode': 'Chat',  
      'meetingobjective': 'Meeting Objective / Title',  
      'date': 'Wed, Nov 9, 2022',  
     },  
    {  
      'id': 2,   
      'projectname': 'Closetlondon',  
      'clientname': '	Jay Rathore',  
      'duration': '30 minutes',  
      'mode': 'Chat',
      'meetingobjective': 'Meeting Objective',  
      'date': 'Tus, Jan 19, 2022',      
    },  
    {  
      'id': 3,   
      'projectname': 'Aposto website',  
      'clientname': 'Michael Lam',  
      'duration': '20 minutes',  
      'mode': 'Chat',
      'meetingobjective': 'Objective',  
      'date': 'Tus, Jan 19, 2022',  
    },  
  ];  

  return (
    <>
        <div className="page-container relative h-full flex flex-auto flex-col">
        <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-6">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
                  <div className="">
                      <h3 className='text-3xl'>Checklist Group</h3>
                      <span className='block text-xs mt-1 text-gray-400'>15 Checklist</span>
                  </div>
                  <div className="flex items-center gap-2">
                      <div className="">
                      <Button onClick={() => openDialog()}  variant="solid">Add New</Button>
                      <Dialog
				isOpen={dialogIsOpen}
				onClose={onDialogClose}
				onRequestClose={onDialogClose}
				width={800}
               
			>
                <h5 className="mb-4">Add New Checklist Group</h5>
 <NewForm />

            </Dialog>
                      
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div className="container mx-auto h-full px-6">
          <div className="mt-6 h-full flex flex-col">
          {checklists.map((checklist, index) => (  
              <div className="mb-4" data-index={index} key={index}>
                <Card bordered={cardBorder}>
                    <div className='grid gap-x-4 grid-cols-12'>
                        <div className="col-span-12 lg:col-span-3 lg:flex lg:items-center">
                          <div className="flex flex-col">
                              <h6 className='font-bold'><Link to="psd-to-html-101">{checklist.projectname}</Link></h6>  
                          </div>
                          </div>

                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-1 md:flex md:items-center">
						<div className="inline-flex items-center px-2 py-1 border border-gray-300 rounded-full">
							<HiOutlineClipboardCheck className="text-base" />
							<span className="ml-1 rtl:mr-1 whitespace-nowrap">35 / 40</span>
						</div>
					</div>

                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-2 md:flex md:items-center">
                          {checklist.date}
                          </div>
                          

                         

                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-2 md:flex md:items-center">
                            <Avatar.Group
                            chained
                            maxCount={3} 
                            omittedAvatarProps={{shape: 'circle'}}
                            omittedAvatarTooltip
                            onOmittedAvatarClick={() => console.log('Omitted Avatar Clicked')}
                            >
                              <Tooltip title="Gaurav Saini"><Avatar size="sm" shape="circle" src={gaurav} /></Tooltip>
                              <Tooltip title="Vijendra Singh Rathore"><Avatar size="sm" shape="circle" src={vsr} /></Tooltip>
                              <Tooltip title="Devendra Singh Nathawat"><Avatar size="sm" shape="circle" src={devendra} /></Tooltip>
                              <Tooltip title="Anand Saini"><Avatar size="sm"  shape="circle" src={anand} /></Tooltip>
                            
                            
                            </Avatar.Group>
                              
                          </div>

                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-1 md:flex md:items-center">
                            <Tooltip title="TL - Sandeep Sharma"><Avatar size="sm" shape="circle"  src={sandeep} /></Tooltip>
                          </div>

                          <div className="my-1 lg:my-0 col-span-12 lg:col-span-2 md:flex md:items-center">
                            <Tooltip title="BA - Vijendra Verma"><Avatar size="sm" shape="circle"  src={vijendraverma} /></Tooltip>
                          </div>

                          <div className="flex lg:justify-end text-lg  lg:items-center">
                          <Tooltip title="Duplicat Checklist">
                            <Link to="#" className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiDuplicate/>
                            </Link>
                            </Tooltip>
                            <Link to="#" className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiOutlinePencilAlt/>
                            </Link>
                            <Link to="#" className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiTrash/>
                            </Link>
                          </div>
                    </div>
                </Card>
              </div>

          ))}  
          </div>
        </div>
      </div>
    </>
  )
}

export default ChecklistGroup