import { Button, Input, ScrollBar, Select } from 'components/ui'
import React from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

const checkList = [
    {id: 1, name: 'Doctype', category: 'Head'},
    {id: 2, name: 'Viewport', category: 'Head'},
    {id: 3, name: 'HTML5 Semantic Elements', category: 'Html'},
    {id: 4, name: 'Webfont format', category: 'webfonts'},
    {id: 5, name: 'Responsive Web Design', category: 'css'},
  ];

  const selectCategory = [
    { value: 'html', label: 'HTML' },
    { value: 'css', label: 'CSS' },
    { value: 'performance', label: 'Performance' },
  ]

const AddNewChecklistItem = () => {
  return (
    <>
        <div className="text-center mb-6">
            <h4 className="mb-2">Add Checklist Item</h4>
        </div>
        
            
            <div className="flex flex-row space-x-4">
  <div className="basis-1/2">
  <Input 
				prefix={<HiOutlineSearch className="text-lg" />} 
				placeholder="Quick Search"
			/>
  </div>
  <div className="basis-1/2">
  <Select 
                                     className="min-w-[120px]"
                                  options={selectCategory}
                                  />
  </div>
</div>

        <div className="mt-4">
        <p className="font-semibold uppercase text-xs mb-4">1200 Items available</p>
            <div className="overflow-y-auto h-80 mb-6">
                <ScrollBar>
                    <div  className='py-3 pr-5 rounded-lg flex items-center justify-between'>
                        <div className="flex items-center gap-2">Desktop Browsers</div>
                        <Button size="xs"><span className="text-red-500">Remove</span></Button>
                    </div>
                    <div  className='py-3 pr-5 rounded-lg flex items-center justify-between'>
                        <div className="flex items-center gap-2">Title</div>
                        <Button size="xs"><span className="text-red-500">Remove</span></Button>
                    </div>
                    {checkList.map((item, index) => (
                    <div key={index} className='py-3 pr-5 rounded-lg flex items-center justify-between'>
                        <div className="flex items-center gap-2">{item.name}</div>
                        <Button size="xs">Add</Button>
                    </div>
                    ))}
                </ScrollBar>
            </div>
            <Button block>Done</Button>
        </div>
    </>
  )
}

export default AddNewChecklistItem