import { FormContainer, FormItem, Input, Select, TimeInput, DatePicker, Button  } from 'components/ui'
import React from 'react'

const ClientName = [
  { value: 'AliceGauthier', label: 'Alice Gauthier' },
  { value: 'JayRathore', label: 'Jay Rathore' },
  { value: 'JeremySmall', label: 'Jeremy Small' },
  { value: 'DedicatedNerder', label: 'Dedicated Nerder' },
  { value: 'Cameron', label: 'Cameron' },
]

const ProjectName = [
  { value: 'one', label: 'SkyMinetta Bay(D)' },
  { value: 'two', label: 'Mocha Cabana  ' },
]

const AssignmentName = [
  { value: 'one', label: 'Minette Bay- Website Development' },
  { value: 'two', label: 'number of updates: CAGP  ' },
]

const membersOptions = [
    { value: 'abhisheqverma', label: 'Abhisheq Verma' },
    { value: 'vijendraverma', label: 'Vijendra Verma' },
    { value: 'yoshitaacholy', label: 'Yoshita Pacholy' },
    { value: 'nitinpratapsingh', label: 'Nitin Pratap Singh' },
    { value: 'dineshsharma', label: 'Dinesh Sharma' },
    { value: 'abbhijeetbhushan', label: 'Abhijeet Bhushan' },
    { value: 'naveengupta', label: 'Naveen Gupta' },
    { value: 'gauravsaini', label: 'Gaurav Siani' },
  ]
  
  const selectBA = [
    { value: 'vijendraverma', label: 'Vijendra Verma' },
    { value: 'yoshitaacholy', label: 'Yoshita Pacholy' },
  ]

  const selectTL = [
    { value: 'sandeepsharma', label: 'Sandeep Sharma' },
  ]

const NewForm = () => {

  return (
    <FormContainer>

<div className="flex flex-row space-x-4">
  <div className="basis-1/2">
  <FormItem label="Name*">
    <Input />
  </FormItem>
  </div>
  <div className="basis-1/2">
  <FormItem label="Client Name">
                              <Select 
                                  className="min-w-[120px]"
                                  options={ClientName}
                                  />
                              </FormItem>
  </div>
</div>

<div className="flex flex-row space-x-4">
  <div className="basis-1/2"><FormItem label="Project Name">
                              <Select 
                                  className="min-w-[120px]"
                                  options={ProjectName}
                                  />
                              </FormItem></div>
  <div className="basis-1/2"><FormItem label="Assignment Name">
                              <Select 
                                  className="min-w-[120px]"
                                  options={AssignmentName}
                                  />
                              </FormItem></div>
</div>

<div className="flex flex-row space-x-4">
  <div className="basis-1/2"><FormItem label="Select a team member">
                                  <Select 
                                  isMulti
                                  className="min-w-[120px]"
                                  options={membersOptions}
                                  />
                              </FormItem></div>
  <div className="basis-1/2"><FormItem label="Select TL">
                                  <Select 
                                  className="min-w-[120px]"
                                  options={selectBA}
                                  />
                              </FormItem></div>
</div>

<div className="flex flex-row space-x-4">
  <div className="basis-1/2"><FormItem label="Select BA">
                                  <Select 
                                     className="min-w-[120px]"
                                  options={selectTL}
                                  />
                              </FormItem></div>
  <div className="basis-1/2">
  <FormItem label="Due date">
                              <DatePicker placeholder="Pick a date" />
                              </FormItem>
  </div>
</div>
                              
                              
                              
<div className="text-right mt-6">
					<Button className="ltr:mr-2 rtl:ml-2" variant="plain">Cancel</Button>
					<Button variant="solid">Submit</Button>
				</div>     
                              
                              
                              
                             
                          </FormContainer>
  )
}

export default NewForm