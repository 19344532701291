import { RichTextEditor } from 'components/shared'
import { FormContainer, FormItem, Input, Select, TimeInput, DatePicker, Button  } from 'components/ui'
import React from 'react'


const category = [
  { value: 'html', label: 'HTML' },
  { value: 'css', label: 'CSS' },
]

 
  
  

  const handleChange = (val) => {
		console.log('value', val)
	}

const NewForm = () => {

  return (
    <FormContainer>

<div className="flex flex-row space-x-4">
  <div className="basis-1/2">
  <FormItem label="Name">
  <Input placeholder="Name" />
                              </FormItem>
  </div>
  <div className="basis-1/2">
<FormItem label="Category">
<Select 
className="min-w-[120px]"
options={category}
/>
</FormItem>
  </div>
</div>

 

<RichTextEditor onChange={handleChange} />
                             
                              
<div className="text-right mt-6">
					<Button className="ltr:mr-2 rtl:ml-2" variant="plain">Cancel</Button>
					<Button variant="solid">Submit</Button>
				</div>     
                              
                              
                              
                             
                          </FormContainer>
  )
}

export default NewForm