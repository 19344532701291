import { Card, Dialog, Drawer, Button, Tooltip, Avatar, Input } from 'components/ui'
import React from 'react'
import { useState } from 'react';
import { HiOutlinePencilAlt, HiOutlineSearch, HiTrash } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import NewForm from './NewForm';

import sandeep from '../../assets/images/avatars/sandeep.jpg';

const CheckList = () => {

  const [dialogIsOpen, setIsOpen] = useState(false)

const openDialog = () => {
setIsOpen(true)
}

const onDialogClose = e => {
console.log('onDialogClose', e)
setIsOpen(false)
}

//

const [isOpen, setIsViewOpen] = useState(false)

	const openDrawer = () => {
		setIsViewOpen(true)
	}

	const onDrawerClose = e => {
		console.log('onDrawerClose', e)
		setIsViewOpen(false)
	}

  //
  const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }


  const checklists = [  
    {  
      'id': 1,   
      'name': 'Images Optimized',  
      'category': 'Performance',  
     },  
    {  
      'id': 2,   
      'name': 'HTML5 Semantic Elements',  
      'category': '	HTML',  
    },  
    {  
      'id': 3,   
      'name': 'W3C compliant',  
      'category': 'HTML',  
    },
    {  
      'id': 4,   
      'name': '404 Page',  
      'category': 'HTML',  
    },  
    {  
      'id': 5,   
      'name': 'Responsive Web Design',  
      'category': 'CSS',  
    },  
    {  
      'id': 6,   
      'name': 'Desktop Browsers',  
      'category': 'CSS',  
    }, 
    {  
      'id': 7,   
      'name': 'CSS: No usage of @import',  
      'category': 'CSS',  
    },    
  ];  


  return (
    <>
    <div className="page-container relative h-full flex flex-auto flex-col">
        <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-6">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
                  <div className="">
                      <h3 className='text-3xl'>Checklist</h3>
                      <span className='block text-xs mt-1 text-gray-400'>200 item</span>
                  </div>
                  <div className="flex items-center gap-2">
                  <Input 
				 
					 
					placeholder="Search" 
					prefix={<HiOutlineSearch className="text-lg" />}
				 
				/>
                      <div className="">
                      <Button variant="solid" onClick={() => openDialog()}>Add New</Button>
                      <Dialog
				isOpen={dialogIsOpen}
				onClose={onDialogClose}
				onRequestClose={onDialogClose}
				width={800}
               
			>
                <h5 className="mb-4">Add New Checklist</h5>
 <NewForm />

            </Dialog>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div className="container mx-auto h-full px-6">
          <div className="mt-6 h-full flex flex-col">
              
              {checklists.map((checklist, index) => (  
                <div className="mb-4">
                <Card>
                    <div className='grid gap-x-4 grid-cols-12'>
                      <div className="col-span-12 lg:col-span-3 lg:flex lg:items-center">
                        <div className="flex flex-col">
                        <h6 className='font-bold'><Link to="#" onClick={() => openDrawer()}>{checklist.name}</Link></h6> 
                        <Drawer
				title="Checklist Item"
				isOpen={isOpen}
				onClose={onDrawerClose}
				onRequestClose={onDrawerClose}
        placement='left'
			>
        <div className="mb-5">
          <h6>Name</h6>
          Images Optimized
        </div>

        <div className="mb-5">
          <h6>Category</h6>
          HTML
        </div>
				
        <div className="mb-5">  
        <h6>Description</h6>    
        <ul>
                            <li>Compress all your images using either web processors such as <Link to="https://web.archive.org/web/20190624185304/http://optimizilla.com/" >Optimizilla.com</Link> and <Link to="https://web.archive.org/web/20190624185304/https://compressor.io/compress" >Compressor.io</Link>, or desktop apps such as <Link  rel="noopener" to="https://web.archive.org/web/20190624185304/http://nikkhokkho.sourceforge.net/static.php?page=FileOptimizer">FileOptimizer</Link>
                                and <Link to="https://web.archive.org/web/20190624185304/https://imageoptim.com/mac">ImageOptim</Link>.
                            </li>
                            <li>Generate the exact image size for each element instead of resizing it with CSS/HTML as
                                this can be a heavy process for the browser to perform.
                            </li>
                            <li>Utilize <Link to="https://web.archive.org/web/20190624185304/https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Images/Implementing_image_sprites_in_CSS">Image
                                Sprites</Link> to save on HTTP requests and bandwidth.
                            </li>
                        </ul>
                        </div>
			</Drawer> 
                        </div>
                      </div>

                      <div className="col-span-12 lg:col-span-3 lg:flex lg:items-center">
                        {checklist.category}  
                      </div>

                      <div className="my-1 lg:my-0 col-span-12 lg:col-span-2 md:flex md:items-center">
                            <Tooltip title="TL - Sandeep Sharma"><Avatar size="sm" shape="circle"  src={sandeep} /></Tooltip>
                          </div>

                      <div className="flex lg:justify-end text-lg  lg:items-center">
                            <Link to="#" className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiOutlinePencilAlt/>
                            </Link>
                            <Link to="#" className='cursor-pointer p-2 hover:text-blue-600'>
                              <HiTrash/>
                            </Link>
                          </div>
                    </div>
                </Card>
                </div>
                ))}  

        <div className="flex justify-center">
             <Button  variant="solid" loading={loading} onClick={onClick}>More</Button>
        </div>
              
          </div>
        </div>

       
      </div>
    </>
  )
}

export default CheckList