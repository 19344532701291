import React, { useCallback, useState } from 'react'
import { Button, Card } from 'components/ui'
import { HiDownload, HiPlusSm, HiMinusSm } from 'react-icons/hi'
import { CgCodeSlash, CgCode, CgCopy } from 'react-icons/cg'
import ChecklistTable from './ChecklistTable'
import ChecklistHeader from './ChecklistHeader'




const ChecklistGroupDetail = () => {
    const [expand, setExpand] = useState(false)

    const onExpand = useCallback(() => {
    setExpand(!expand)
    }, [expand])

    

  return (
    <div className="page-container relative h-full flex flex-auto flex-col">

        <ChecklistHeader />

       

        <div className="container mx-auto h-full px-6 py-6">

        <div className="grid grid-cols-3 gap-8">
            <Card header={<span>Head</span>}>
            <ChecklistTable />
            <ChecklistTable />
            <ChecklistTable />
            </Card>

            <Card header={<span>Head</span>}>
            <ChecklistTable />
            <ChecklistTable />
            <ChecklistTable />
            </Card>

            <Card header={<span>Head</span>}>
            <ChecklistTable />
            <ChecklistTable />
            <ChecklistTable />
            </Card>

            <Card header={<span>Head</span>}>
            <ChecklistTable />
            <ChecklistTable />
            <ChecklistTable />
            </Card>

            <Card header={<span>Head</span>}>
            <ChecklistTable />
            <ChecklistTable />
            <ChecklistTable />
            </Card>

            <Card header={<span>Head</span>}>
            <ChecklistTable />
            <ChecklistTable />
            <ChecklistTable />
            </Card>
        </div>

        </div>
      </div>
  )
}

export default ChecklistGroupDetail