import { ActionLink, PasswordInput } from 'components/shared'
import { Button, Card, Input, FormContainer, FormItem, Checkbox } from 'components/ui'
import React from 'react'
import { Link } from 'react-router-dom'

import Logo from 'assets/images/logo.svg'

const Login = () => {

    const forgotPasswordUrl = '/forgot-password'


  return (
    <>
      <div className="app-layout-blank flex flex-auto flex-col h-[100vh]">
          <div className="h-full flex flex-auto flex-col justify-between">
              <main className='h-full'>
                  <div className="page-container relative h-full flex flex-auto flex-col">
                      <div className="h-full">
                          <div className="container mx-auto flex flex-col flex-auto items-center justify-center min-w-0 h-full">
                              <Card className="min-w-[320px] md:min-w-[450px]" bodyClass="md:p-10">
                              <div className="text-center">
                                    <div className="logo mx-auto mb-4"><img src={Logo} alt="xChecklist - Xieno" className='mx-auto w-8' /></div>
                                    </div>
                                    <div className="text-center">
                                    <div className="mb-4"><h3 className="mb-1">Login</h3>
                                    <p>Please enter your credentials to sign in!</p>
                                    </div>
                                        <form action='dashboard'>
                                            <FormContainer>
                                                <FormItem label="Email">
                                                    <Input type="email" placeholder="yourname@mail.com" autoComplete="off" />
                                                </FormItem>
                                                <FormItem label="Password">
                                                    <PasswordInput />
                                                </FormItem>
                                                <div className="flex justify-between mb-6">
                                                <Checkbox>Remember Me</Checkbox>
								<ActionLink to={forgotPasswordUrl}>
									Forgot Password?
								</ActionLink>
							</div>
                                                <Button block  variant="solid" type="submit" >
                                                    Sign In
                                                </Button>
                                                
                                            </FormContainer>
                                        </form>
                                        
                                    </div>
                              </Card>
                          </div>
                      </div>  
                  </div>
              </main>
          </div>
      </div>
    </>
  )
}

export default Login