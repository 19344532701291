import { ActionLink } from 'components/shared'
import { Button, Card, Input, FormContainer, FormItem } from 'components/ui'
import React from 'react'
import Logo from 'assets/images/logo.svg'

const ForgotPassword = () => {
    const signInUrl = '/'
  return (
    <div className="app-layout-blank flex flex-auto flex-col h-[100vh]">
          <div className="h-full flex flex-auto flex-col justify-between">
              <main className='h-full'>
                  <div className="page-container relative h-full flex flex-auto flex-col">
                      <div className="h-full">
                          <div className="container mx-auto flex flex-col flex-auto items-center justify-center min-w-0 h-full">
                              <Card className="min-w-[320px] md:min-w-[450px]" bodyClass="md:p-10">
                              <div className="text-center">
                                    <div className="logo mx-auto mb-4"><img src={Logo} alt="xChecklist - Xieno" className='mx-auto w-8' /></div>
                                    </div>
                                    <div className="text-center">
                                    <div className="mb-4">
                                        <h3 className="mb-1">Forgot Password</h3>
                                        <p>Please enter your email address to receive a verification code</p>
                                        </div>
                                        <form action='dashboard'>
                                            <FormContainer>
                                                <FormItem label="User Name">
                                                    <Input type="email" placeholder="yourname@mail.com" autoComplete="off" />
                                                </FormItem>

                                                <Button block variant="solid"  type="submit">Send Email</Button>
                                                <div className="mt-4 text-center">
								<span>Back to </span>
								<ActionLink to={signInUrl}>
									Sign in
								</ActionLink>
							</div>
                                            </FormContainer>
                                        </form>
                                        
                                    </div>
                              </Card>
                          </div>
                      </div>  
                  </div>
              </main>
          </div>
      </div>
  )
}

export default ForgotPassword