import { Routes, Route } from "react-router-dom"
import AppLayout from "../layout/AppLayout"
import Dashboard from "../../views/dashboard"
import Error from "../../views/error"
import Login from "../../views/auth/SignIn"
import Checklist from "views/checklist"
//import ChecklistDetail from "views/checklist/ChecklistDetail"


import ChecklistGroup from "views/checklistgroup"
import ChecklistGroupDetail from "views/checklistgroup/ChecklistGroupDetail"
import ForgotPassword from "views/auth/ForgotPassword"


const Routing = () => {
  return (
    <>
         <Routes>
            <Route path='/' element={<Login />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route element={<AppLayout />} >
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='checklist' element={<Checklist />} />
              {/* <Route path='checklist/:id' element={<ChecklistDetail />} /> */}
       
              <Route path='clgroup' element={<ChecklistGroup />} />
              <Route path='clgroup/:id' element={<ChecklistGroupDetail />} />

              
            </Route>
            <Route path="*" element={<Error />} />
        </Routes>
    </>
  )
}

export default Routing