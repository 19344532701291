import { Button, Tooltip, Dialog, Avatar } from 'components/ui'
import React, { useState } from 'react'
import { HiDownload, HiOutlineCog, HiOutlineUserAdd } from 'react-icons/hi'

import gaurav from '../../assets/images/avatars/gaurav.jpg';
import vsr from '../../assets/images/avatars/vsr.jpg';
import devendra from '../../assets/images/avatars/devendra-singh-nathawat.jpg';
import anand from '../../assets/images/avatars/anand-saini.jpg';
import sandeep from '../../assets/images/avatars/sandeep.jpg';
import vijendraverma from '../../assets/images/avatars/vv.jpg';

import AddNewChecklistItem from './AddNewChecklistItem';

const ChecklistHeader = () => {

// Dialog
const [dialogIsOpen, setIsOpen] = useState(false)

	const openDialog = () => {
		setIsOpen(true)
	}

	const onDialogClose = e => {
		console.log('onDialogClose', e)
		setIsOpen(false)
	}

	const onDialogOk = e => {
		console.log('onDialogOk', e)
		setIsOpen(false)
	}

  return (
    <>
     <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-6">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
                  <div className="">
                  <h3 className='text-3xl'>PSD to HTML and WordPress</h3>
                  <p className="mb-1">Belgraviat</p>
                  </div>
                  <div className="flex items-center gap-2">

                  <Avatar.Group
                            chained
                            maxCount={2} 
                            omittedAvatarProps={{shape: 'circle'}}
                            omittedAvatarTooltip
                            onOmittedAvatarClick={() => console.log('Omitted Avatar Clicked')}
                            >
                              <Tooltip title="Gaurav Saini"><Avatar size="sm" shape="circle" src={gaurav} /></Tooltip>
                              <Tooltip title="Vijendra Singh Rathore"><Avatar size="sm" shape="circle" src={vsr} /></Tooltip>
                              <Tooltip title="Devendra Singh Nathawat"><Avatar size="sm" shape="circle" src={devendra} /></Tooltip>
                              <Tooltip title="Anand Saini"><Avatar size="sm"  shape="circle" src={anand} /></Tooltip>
                            
                            
                            </Avatar.Group>

                  
       
                      
                  </div>
              
              
              </div>

              <div className='flex flex-col lg:flex-row justify-between lg:items-center gap-4'>
                <div className=""></div>
                <div className="flex items-center gap-2">
                <Button size="sm" icon={<HiOutlineUserAdd />} onClick={() => openDialog()}>Add Checklist Item</Button>
                <Button size="sm" icon={<HiOutlineUserAdd />} >Add people</Button>
                     
                </div>
              </div>
          </div>
        </div>


        <Dialog
				isOpen={dialogIsOpen}
				onClose={onDialogClose}
				onRequestClose={onDialogClose}
        //width= '520'
			>
                <AddNewChecklistItem />
			</Dialog>
    </>
  )
}

export default ChecklistHeader