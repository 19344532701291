import { BrowserRouter} from "react-router-dom"
import Routing from "./components/routes";


function App() {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
}

export default App;