import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../template/Header";
import Sidebar from "../template/Sidebar";
import Footer from "../template/Footer";
const AppLayout = () => {
  return <>
    <div className="app-layout-classic flex flex-auto flex-col">
        <div className="flex flex-auto min-w-0">
          <Sidebar />
          <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full">
            <Header />
            <div className="h-full flex flex-auto flex-col justify-between">
                <main className="h-full">
                   
                    <Outlet />
                   
                </main>
                <Footer />
            </div>
            
          </div>{/* END .flex flex-col flex-auto min-h-screen min-w-0 relative w-full  */}
        </div>{/* END .flex flex-auto min-w-0 */}
    </div>{/* END .app-layout-classic flex flex-auto flex-col */}
    
  </>;
};
export default AppLayout;
